<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th>Importo</th>
            <th>Stato</th>
            <th>Data di creazione</th>
            <th>Data di arrivo</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <PayoutRow
            :payout="payout"
            v-for="payout in payouts"
            v-bind:key="payout.id"
        ></PayoutRow>
        </tbody>
    </table>
</template>

<script>

import PayoutRow from "./PayoutRow";

export default {
    name: "PayoutsTable",
    components: {PayoutRow},
    props: {
        payouts: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
