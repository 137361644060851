import {URL_API_PAYOUTS, URL_API_RELATIVE_TRANSACTIONS} from "../common/constants";
import {client, generateAuthorizationHeader} from "./index"


export const getPayouts = (limit, starting_after=null) => {
    let url = URL_API_PAYOUTS + "?limit=" + limit;

    if (starting_after) {
        url += '&starting_after=' + starting_after;
    }


    return client
        .get(url, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getPayoutDetail = (payout_id) => {
    let url = URL_API_PAYOUTS + payout_id;

    return client
        .get(url, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const getPayoutTransactions = (payout_id, limit, starting_after=null) => {
    let url = URL_API_PAYOUTS + payout_id + "/" + URL_API_RELATIVE_TRANSACTIONS + "?limit=" + limit;

    if (starting_after) {
        url += '&starting_after=' + starting_after;
    }


    return client
        .get(url, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};
