<template>
    <nav class="navbar navbar-default hide-print">
        <div class="container-fluid">
            <div class="navbar-header">
                <router-link class="navbar-brand" to="/reservations">
                    Manager Riviera Nuragica
                </router-link>
            </div>
            <div class="pull-right">
                <p class="navbar-text hidden-xs hidden-sm">
                    Ciao, {{user.display_name}}
                </p>
                <i class="las la-bars visible-xs visible-sm" data-toggle="collapse" data-target="#left-menu" aria-expanded="false"></i>


            </div>
        </div>
    </nav>
</template>

<script>
    import {StorageHelper} from "../../common/storageHelper";

    export default {
        name: "Header",
        data : function () {
            return {
                user: {},
            }
        },
        mounted() {
            this.user = StorageHelper.getUser();
        }
    };
</script>
