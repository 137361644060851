<template>
    <tr>
        <td>{{Utils.getCurrencySymbolFromString(payout.currency)}}{{(payout.amount/100).toFixed(2)}}</td>
        <td><span :class="Utils.getLabelClassStatusPayout(payout.status)" class="label">{{StringsHelper.getStatusPayout(payout.status)}}</span></td>
        <td>{{Utils.getDateStringFromISO(payout.created * 1000)}}</td>
        <td>{{Utils.getShortDateStringFromISO(payout.arrival_date * 1000)}}</td>
        <td class="text-right"><a href="" @click.prevent.stop="$router.push({ name: 'payout_detail', params: { payout_id: payout.id }})" class="btn btn-default"><i class="las la-file-invoice-dollar"></i>Mostra transazioni</a></td>
    </tr>
</template>

<script>
import baseMixin from "../../common/baseMixin";

export default {
    name: "PayoutRow",
    mixins: [baseMixin],
    props: {
        payout: {
            type: Object,
            require: true
        }
    }
};
</script>
