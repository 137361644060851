<template>
    <ContainerWithMenu>
        <div class="page-content">
            <h1 class="h2 margin-bottom-20">Bonifici</h1>
            <div class="table-responsive" v-if="payouts.length > 0">
                <PayoutsTable :payouts="payouts"></PayoutsTable>
            </div>
            <div v-else>
                <span class="text-muted">Nessun bonifico trovato</span>
            </div>
            <div class="text-center" v-if="has_more">
                <a href="" @click.prevent.stop="showMoreCallback" class="btn btn-primary"><i class="las la-download"></i> Mostra altre </a>
            </div>
        </div>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {getPayouts} from "../api/payments";
    import SpinnerLoader from "../components/common/SpinnerLoader";
    import PayoutsTable from "../components/Payout/PayoutsTable";
    import {LIMIT_RECORDS_API} from "../common/constants";

    export default {
        title: "Bonifici",
        name: "Payouts",
        components: { ContainerWithMenu, SpinnerLoader, PayoutsTable},
        data: () => {
            return {
                payouts: [],
                limitPayouts: LIMIT_RECORDS_API,
                loading: false,
                has_more: false
            }
        },
        methods: {
            async getPayouts(starting_after=null) {
                this.loading = true;
                try {
                    let payoutsResponse = await getPayouts(this.limitPayouts, starting_after);
                    this.payouts = this.payouts.concat(payoutsResponse.data);
                    this.has_more = payoutsResponse.has_more;
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            showMoreCallback() {
                this.getPayouts(this.payouts[this.payouts.length-1].id)
            }
        },
        mounted() {
            this.getPayouts()
        }
    };
</script>
